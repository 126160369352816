<template>
  <div>
    <b-row class="mb-2" >
      <b-col xs="6" sm="6" md="4">
        <go-back :title="$t('personnel.table.title')">
          <b-button variant="primary" block :to="{ name: 'personnel-create' }" v-if="$auth.check('user-create')">
            <feather-icon icon="PlusIcon" class="mr-50"  />
            {{ $t("personnel.actions.add") }}
          </b-button>
        </go-back>
      </b-col>
    </b-row>
    <b-card no-body>
      <b-card-header>
        <b-card-title>{{ $t("personnel.table.title") }}</b-card-title>
      </b-card-header>
      <data-table
        :fetchData="fetchData"
        :columns="columns"
        v-if="$auth.check('user-list')"
      >
        <template slot="row" slot-scope="props">
          <!-- Column: Name -->
          <user :user="props.row" v-if="props.column.field === 'name'" />

          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'status'">
            <b-badge
              class="d-block"
              :variant="
                props.row.status == 'active' ? 'light-success' : 'light-warning'
              "
            >
              {{ $t("common.status." + props.row.status) }}
            </b-badge>
          </span>

          <!-- Column: KPI's -->
          <span v-else-if="props.column.field === 'kpis'">
            <kpis-show :kpis="props.row.kpis"/>
          </span>

          <!-- Column: Created at -->
          <span v-else-if="props.column.field === 'last_login_at'">
            {{
              props.row.last_login_at
                ? $d(new Date(props.row.last_login_at * 1000), "full", "en")
                : $t("common.never")
            }}
          </span>

          <!-- Column: Created at -->
          <span v-else-if="props.column.field === 'created_at'">
            {{ $d(new Date(props.row.created_at * 1000), "long", "en") }}
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <action-button
                icon="EyeIcon"
                :title="$t('personnel.actions.show')"
                :to="{ name: 'personnel-show', params: { id: props.row.id } }"
                permissions="user-show"
              />
              <action-button
                icon="Edit2Icon"
                :title="$t('personnel.actions.update')"
                :to="{ name: 'personnel-edit', params: { id: props.row.id } }"
                permissions="user-update"
              />
              <action-button
                icon="KeyIcon"
                :title="$t('personnel.actions.password.action')"
                @click="() => resendPasswordLink(props.row.id)"
                permissions="user-update"
              />
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </data-table>
    </b-card>
  </div>
</template>

<script>
import DataTable from "@/components/DataTable/DataTable.vue";
import { index } from "@/api/users.api";
import User from "@/components/User.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ActionButton from "@/components/DataTable/ActionButton.vue";
import { requestPasswordUpdate } from "@/api/auth.api";
import GoBack from "@/components/Common/GoBack.vue";
import KpisShow from "@/components/Kpis/Show.vue"

export default {
  name: "personnel-list",
  components: { DataTable, User, ActionButton, GoBack, KpisShow },
  data() {
    return {
      columns: [
        {
          label: this.$t("fields.name"),
          field: "name",
          filterOptions: {
            enabled: true,
            placeholder: this.$t("common.actions.filter"),
          },
        },

        {
          label: this.$t("fields.organization_name"),
          field: "organization.name",
          guard_name: "management",
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: this.$t("common.actions.filter"),
          },
        },
        { label: this.$t("fields.status"), field: "status" },
        {
          label: this.$t("fields.kpis"),
          field: "kpis",
          sortable: false,
          guard_name: "management",
        },
        { label: this.$t("fields.last_login_at"), field: "last_login_at" },
        { label: this.$t("fields.created_at"), field: "created_at" },
        {
          label: "Actions",
          field: "action",
          sortable: false,
          width: "140px",
        },
      ]
    };
  },
  methods: {
    async fetchData(filters) {
      return await index(filters);
    },
    resendPasswordLink(id) {
      this.$swal({
        title: this.$t("auth.reset-password.title"),
        text: this.$t("auth.reset-password.modal.message"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("common.actions.confirm"),
        cancelButtonText: this.$t("subscription.actions.cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (!result.value) return;
        const { data, error } = await requestPasswordUpdate(id);

        if (data) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("personnel.actions.password.success"),
              icon: "CheckIcon",
              variant: "success",
            },
          });
        }
        // TODO: genel hata veya yetki hatası
      });
    },
  },
};
</script>

<style>
</style>